<template>
  <div class="ant-card " style="height:250px;">
    <v-form ref="loginform" v-model="valid" lazy-validation>
      <div class="card-header ">
        <h1 class="float-left mt-3  ml-4">Account</h1>

        <div class="float-right">
          <v-btn
            type="submit"
            class="primary mt-3 mr-5"
            :disabled="!valid"
            @click.prevent="account_submit"
          >
            submit
          </v-btn>
        </div>
      </div>
      <v-alert
        :type="actionClass"
        class="mr-15"
        v-if="message"
        dismissible
      >
        {{ message }}
      </v-alert>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="firstname"
              :rules="nameRules"
              :counter="20"
              label="First name"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="lastname"
              :rules="nameRules"
              :counter="20"
              label="Last name"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
              required
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date of birth"
                  readonly
                  :rules="dateRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="$refs.dialog.save(date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <div></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validationMxn from '@/mixins/validation_mixin';

export default {
  name: "accountComponent",
  mixins: [validationMxn],
  data() {
    return {
      actionClass: '',
      message: '',
      valid: true,
      firstname: "",
      lastname: "",
      date: "",
      modal: false,
      nameRules: [
        v => !!v || "Name is required",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      dateRules: [v => !!v || "Date is required"]
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  mounted() {
    this.prefill();
  },
  methods: {
    async account_submit() {
      if (!this.$refs.loginform.validate()) {
        return;        
      }
      const payload = {
        first_name: this.firstname,
        last_name: this.lastname,
        dob: this.date,
      };
      const sessionData = this.getSession;
      const fullPayload = {
        params: payload,
        endpoint: `/auth/user-update/${sessionData.id}`
      };

      const response = await this.performUpdateActions(fullPayload);
      this.actionClass = response.data.status === 'success' ? 'success' : "error";
      this.message = response.data.message;
    },
    async prefill() {
      const sessionData = this.getSession;
      const fullPayload = {
        params: {},
        endpoint: `/auth/user-show/${sessionData.id}`
      };

      const response = await this.performGetActions(fullPayload);
      this.firstname = response.data.first_name;
      this.lastname = response.data.last_name;
      this.date = response.data.dob;
    }
  }
};
</script>
